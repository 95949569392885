/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "@react-navigation/web";
import { FormattedMessage } from "react-intl";

import WETTable from "../../components/WETTable-shared";
import AppContext from "../../components/AppContext-shared";
import BingMap from "../../components/BingMap-shared";
import Spinner from "../../components/Spinner-shared";
import CustomTooltip from "../../components/CustomTooltip-shared";

import { isExternal, domId, sortArrayAlphabetically, currentLang, addLineBreakAfterChar } from "../../utils/miscUtils-shared";
import { showBlanksLast, NON_PRINT_CHAR } from "../../utils/wetUtils-shared";

import './region-shared.css'

//* This page uses a differently primary (area) filter depending on if it's the internal (districts) or external (provinces) site
//* Note that for districts you have to make sure they are numbers vs strings
//TODO: the typing filter still picks up service numbers, not ideal, think of a way to fix it
const Region = ({ navigation, region, initialService }) => {
  const { districts, offices, services, messages, provinces, updateActiveOffice } = useContext(AppContext);

  //Column constants depending on internal/external
  const ID_COLUMN = 0;
  const AREA_FILTER_COLUMN = 3;
  const SERVICE_FILTER_COLUMN = 4;
  const COLUMN_FOR_LINK = 2
  const AIRPORT_COLUMN = 5;

  const SEARCH_BAR_DELAY_MS = 1000;

  const [showSpinner, setShowSpinner] = useState(true);
  const [serviceMap, setServiceMap] = useState(new Map()); //map of services available to filter
  const [officeList, setOfficeList] = useState([]); //list of offices for WET table
  const [mapOfficeList, setMapOfficeList] = useState([]); //list of offices for Map
  const [tableInitialized, setTableInitialized] = useState(false); //list of offices for Map

  const geoList = useRef([]); //list of associated districts/provinces
  const tableRef = useRef(); //ref to the DataTable (referencing directly via window.$... caused re-initialize warnings )
  const initialServiceRef = useRef(initialService);

  //Filter vars
  const [selectedArea, setSelectedArea] = useState([]); //province (external), district (internal)
  const [selectedServices, setSelectedServices] = useState([]);

  const customHeader = [
    messages['label.id'], //hidden
    messages['label.id'], //ACROSS
    messages['label.name'],
    <>
      {/* Province */}
      <span className="display-full">{isExternal() ? messages['label.province'] : messages['label.district']}</span>
      <span className="display-short" title={messages['label.province']}>
        {isExternal() ? messages['label.province.short'] : messages['label.district.short']}
      </span>
    </>,
    'Services', //hidden
    <>
      {/* Airport */}
      <span className="display-full">{messages['label.airport']}</span>
      <span className="display-short" title={messages['label.office.airportCode']}>
        <i className="fa-solid fa-plane-up fa-lg"></i>
      </span>
    </>,
  ]

  let filterTimeoutId; //timeout id for typing filter

  //Get office data for map after filter is applied
  const getOfficesFromIdArray = (officeIds) => {
    const tempOffices = [];
    officeIds.forEach((officeId) => {
      const tempOffice = Array.from(offices.values()).filter(office => office.number === officeId);
      tempOffices.push(tempOffice[0]);
    })
    return tempOffices;
  }

  //Update service map based on currently viewable offices
  const updateServiceMap = (officeNumArray) => {
    const tempServiceMap = new Map();
    officeNumArray.forEach((officeNum) => {
      offices.get(+officeNum)?.services?.forEach((serviceNum) => {
        tempServiceMap.set(serviceNum, {
          name: services.get(serviceNum)?.name ?? '' + serviceNum,
          count: (tempServiceMap.get(serviceNum)?.count ?? 0) + 1,
        }
        );
      })
    });
    setServiceMap(tempServiceMap);
  }

  //Create list used by WET table
  const createOfficeList = (officeArray) => {
    let officeList = []

    //COLUMNS
    //* NOTE: changes to columns here need to be reflected in the column constants at top of component
    officeArray.forEach((office) => {
      isExternal() ?
        //External columns
        officeList.push([
          office.number, //number used for the <Link dataId>
          office.across,
          <>
            {addLineBreakAfterChar(office.name, '/')}
            {office.alias && <div className="geo-alias">{office.alias}</div>}
          </>,
          //Provinces
          <>
            <span className="display-full">{provinces.get(office.province)?.name}</span>
            <span className="display-short">
              <CustomTooltip placement="top" text={provinces.get(office.province)?.name}>
                {provinces.get(office.province)?.code}
              </CustomTooltip>
            </span>
          </>,
          office.services?.map(number => `<${number}>`).join(','), //convert to custom string: [1,2,3] => '<1>,<2>,<3>' for easier matching
          office.airportCode ? addLineBreakAfterChar(office.airportCode, '/') : NON_PRINT_CHAR, // non-print char used by custom sort
        ])
        :
        //Internal columns
        officeList.push([
          office.number, //number used for the <Link dataId>
          office.across, //across number
          <>
            {office.name} 
            {/* FOR ADMINS - office.inactiveStatus presence means office is not active: -1 => before effective date, 1 => after expiry date. Prop not included if office is active */}
            {!!office.inactiveStatus && 
              <CustomTooltip placement="top" text={messages[office.inactiveStatus > 0 ? 'label.expired' : 'label.pending']}>
                <span className={`fa fa-exclamation-circle ${office.inactiveStatus > 0 ? 'expired-flag' : 'pending-flag'}`} />
                <span className="hide"> 
                  {messages['label.inactive']} {messages[office.inactiveStatus > 0 ? 'label.expired' : 'label.pending']} {/* Hidden text - for searching inactive, expired or pending offices */}
                </span>
              </CustomTooltip>
            }
            {!!office.internalOnly && 
              <CustomTooltip placement="top" text={messages['label.internalSite.text']}>
                <span className='fa fa-circle-minus internal-flag' />
                <span className="hide">
                  {messages['label.internalSite.simple']} {/* Hidden text - for searching inactive, expired or pending offices */}
                </span> 
              </CustomTooltip>
            }
            {office.alias && <div className="geo-alias">{office.alias}</div>}
          </>,
          districts.get(office.districtNumber)?.name,
          office.services?.map(number => `<${number}>`).join(','), //convert to custom string: [1,2,3] => '<1>,<2>,<3>' for easier matching
          office.airportCode ?? NON_PRINT_CHAR // non-print char used by custom sort
        ])
    });
    setOfficeList(sortArrayAlphabetically(officeList, 3));
  }

  const loadRegion = () => {
    //Internal - reset any existing filters from a previous region
    setSelectedArea([]);
    setSelectedServices([]);

    //External - use all offices, Internal: only use offices from specified region
    const geoOffices = region ?
      Array.from(offices.values()).filter(office => office.regionNumber === region) :
      Array.from(offices.values()); //sort provinces alphabetically  
    createOfficeList(geoOffices);
    setMapOfficeList(geoOffices);

    //Create district/province list for filtering
    geoList.current = []
    geoOffices.forEach((office) => {
      const index = geoList.current.findIndex(item => item.key === office[isExternal() ? 'province' : 'districtNumber']);
      if (index === -1) {
        geoList.current.push({
          key: office[isExternal() ? 'province' : 'districtNumber'], count: 1
        });
      }
      else { geoList.current[index].count += 1; }
    });
    sortArrayAlphabetically(geoList.current, 'key')

    updateServiceMap(geoOffices.map(office => office.number));
  }

  //Region load
  useEffect(() => {
    loadRegion();
  }, [region])

  // Apply initial service filter (when coming from the service tab)
  useEffect(() => {
    // Need to wait for 1) WETTable init and 2) area/region filter to be null first 
    if (initialServiceRef.current && region == null && tableInitialized) {
      // setSelectedArea([])
      // loadRegion()
      setSelectedServices([initialService]);
      initialServiceRef.current = null; //null it immediately to make sure only gets applied once
    }
  }, [region, tableInitialized, initialService])

  //Startup
  useEffect(() => {
    //TODO: not sure if this is the best way to do it since this prevents spinner showing up in case page dynamically reloads
    if (!domId("regionOffices").classList.contains('wb-tables-inited')) {
      setShowSpinner(true);
    }
    loadRegion();

    //Hide columns and select region, wait until table is initialized or causes errors
    window.$('#regionOffices').on('init.dt', function () {
      tableRef.current = window.$('#regionOffices').DataTable();
      tableRef.current.column(ID_COLUMN).visible(false);
      tableRef.current.column(SERVICE_FILTER_COLUMN).visible(false);
      tableRef.current.order([2, 'asc']).draw(); //sort table by service name
      
      showBlanksLast('regionOffices', AIRPORT_COLUMN); //Custom sort for airport column

      //Search bar placeholder
      const regionOfficesContainer = document.querySelector('#regionOffices_filter');
      const regionSearchBar = regionOfficesContainer?.querySelector('input[type="search"]');
      if(regionSearchBar) {
        regionSearchBar.placeholder = messages[`label.search.placeholder.${isExternal() ? 'external' : 'internal'}`]; //put placeholder text (we don't control it, DataTables does)
      }

      setTableInitialized(true);
      setShowSpinner(false);

      //Event listener for typing filter to update map
      var searchInput = document.querySelector('input[type="search"]');
      searchInput.oninput = () => {
        clearTimeout(filterTimeoutId);

        //Update map after short delay (debounce) to prevent too many map updates while typing
        filterTimeoutId = setTimeout(() => {
          const filteredOfficeIds = tableRef.current.rows({ filter: 'applied' })?.ids()
          const filteredOfficeIdsArray = Array.from(filteredOfficeIds);
          setMapOfficeList(getOfficesFromIdArray(filteredOfficeIdsArray));
        }, SEARCH_BAR_DELAY_MS);
      }
    });

    domId('find-office')?.scrollIntoView({ behavior: 'smooth' }) //scroll page for optimal viewing
  }, []);

  // Update filter (triggered by area & service filter changes)
  // Note: was getting warnings about re-initializing DataTables when I referenced DataTables using window.$.('#regionOffices')... here for some reason, fixed by assigning it to a useRef (tableRef)
  useEffect(() => {
    if (tableRef.current) {
      //Area filter 
      const areaNames = selectedArea.map(code => isExternal() ?
        provinces.get(code)?.name :
        districts.get(+code)?.name
      )
      const areaFilter = areaNames.join('|').replace(/[()]/g, '\\$&'); //need to escape parentheses '(', ')' characters in district names
      tableRef.current.column(AREA_FILTER_COLUMN).search(areaFilter, true, false);

      //Service filter
      const serviceNums = selectedServices.map(serviceNum => `<${services.get(serviceNum).number}>`)
      //const serviceFilter = serviceNums.join('|');
      let serviceFilter = '(?=.*' + serviceNums.join(')(?=.*') + ')'; // A series of regular expression lookahead, this line of code translates to
      // '(?=.*serviceNum[0])(?=.*serviceNum[1])(?=.*serviceNum[2])...(?=.*serviceNum[n])'
      // effectively means when plug the RE into .search(), it will only search for rows 
      // that have all the selected services, making it an AND instead of OR.
      tableRef.current.column(SERVICE_FILTER_COLUMN).search(serviceFilter, true, false);

      tableRef.current.draw();

      //Get filtered list of office ids
      const filteredOfficeIds = tableRef.current.rows({ filter: 'applied' })?.ids()
      const filteredOfficeIdsArray = Array.from(filteredOfficeIds);

      setMapOfficeList(getOfficesFromIdArray(filteredOfficeIdsArray));
      updateServiceMap(filteredOfficeIdsArray);
    }
  }, [selectedArea, selectedServices]);

  // Select event handlers
  const handleAreaSelect = (event) => {
    const region = event.target.value;
    if (!selectedArea.includes(region)) {
      setSelectedArea([...selectedArea, region]);
    }
    domId('area-select').selectedIndex = 0;
  };

  const handleServiceSelect = (event) => {
    const service = +event.target.value;
    if (!selectedServices.includes(service)) {
      setSelectedServices([...selectedServices, service]);
    }
    domId('service-select').selectedIndex = 0;
  };

  const handleAreaRemove = (regionToRemove) => { setSelectedArea(selectedArea.filter(region => region !== regionToRemove)) };
  const handleServicesRemove = (serviceToRemove) => { setSelectedServices(selectedServices.filter(service => service !== serviceToRemove)) };

  //Construct the filtered geoArray here to make display code in the return easier to read
  const geoArray = geoList?.current.map(({ key, count }) => (
    {
      name: isExternal() ? provinces.get(key)?.name : districts.get(key)?.name,
      key: key,
      count: count
    }
  ));
  sortArrayAlphabetically(geoArray, 'name');

  return (
    <div className="panel-body">
      <Spinner className="spinner" show={showSpinner} />

      <div id="filter-row" className="row">
        {/* AREA (District/Province) */}
        <div className="col-md-6">
          <select id="area-select" className="region-filter-select form-control" onChange={handleAreaSelect} defaultValue='area-default'>
            <option key={'area-filter'} disabled value='area-default'>{messages[isExternal() ? 'label.province.provinceFilter' : 'label.district.districtFilter']}</option>
            {geoArray.map(({ name, key, count }) => (
              <option key={key} value={key}>
                {name} ({count})
              </option>
            ))}
          </select>

          {/* Area chips */}
          <div className="chip-container">
            {selectedArea.map(region => (
              <button id={region} key={region} className="chip-filter" type="button" onClick={() => handleAreaRemove(region)}>
                {isExternal() ?
                  provinces.get(region)?.name :
                  districts.get(+region)?.name
                }
                <span className="fa-solid fa-xmark" />
              </button>
            ))}
          </div>
        </div>

        {/* SERVICES */}
        <div className="col-md-6">
          <select id="service-select" className="region-filter-select form-control" onChange={handleServiceSelect} defaultValue='service-default'>
            <option key={'service-filter'} disabled value='service-default'>{messages['label.region.serviceFilter']}</option>
            {Array.from(serviceMap.entries())
              .sort((a, b) => { return a[1].name.localeCompare(b[1].name) })  //sort alphabetically
              .map(([serviceNum, serviceObj]) => (
                <option key={serviceNum} value={serviceNum}>
                  {serviceObj.name} ({serviceObj.count})
                </option>
              ))}
          </select>

          {/* Service chips */}
          <div className="chip-container">
            {selectedServices.map(service => (
              <button key={service} className="chip-filter" type="button" onClick={() => handleServicesRemove(service)}>
                {services.get(service)?.name}
                <span className="fa-solid fa-xmark" />
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* MAP & FILTERS */}
      <div className="row row-negative-margin-override">
        <BingMap parentName="region" offices={mapOfficeList} navigation={navigation} currentLang={currentLang()} />
        <CustomTooltip placement="bottom" text={messages["label.office.note.map.missingPins"]} className="float-right" >
          <span className="fa-solid fa-circle-info"/>
        </CustomTooltip>
        <WETTable
          headers={customHeader}
          key={region} // key forces WETTable to unmount/re-mount vs update, need this since WET components have issues with dynamic updates
          rows={officeList}
          addHref={{
            useHref: true,
            clickFunction: updateActiveOffice,
            colForLink: COLUMN_FOR_LINK,
            colForData: 0,
          }}
          routeName="Office"
          tableId="regionOffices"
          rowId={0} //column to use for row's ID which we use during filtering
        />
      </div>
    </div>
  );
};

Region.path = "index.html";
Region.navigationOptions = {
  pageTitle: <FormattedMessage id="menu.region" />, //? think it's ok to leave the province one as 'region' as well
  breadcrumb: [
    <Link routeName="Home" params="en">
      <FormattedMessage id="home.title" />
    </Link>,
  ],
  breadcrumbTitle: <FormattedMessage id="menu.region" />,
};

export default Region;