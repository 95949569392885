/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useContext, useEffect, useState } from "react";
import WETTable from "../../components/WETTable-shared";
import AppContext from "../../components/AppContext-shared";
import Spinner from "../../components/Spinner-shared";
import { FormattedMessage } from "react-intl";

import CustomTooltip from "../../components/CustomTooltip-shared";
import { isExternal, domId, addLineBreakAfterChar } from "../../utils/miscUtils-shared";
import { showBlanksLast,NON_PRINT_CHAR } from "../../utils/wetUtils-shared";

const Search = () => {
  const { districts, offices, messages, provinces, updateActiveOffice } = useContext(AppContext);

	const [showSpinner, setShowSpinner] = useState(true);
  const [officeList, setOfficeList] = useState([]); //list of offices for WET table

  const AIRPORT_COLUMN = 4;

  const customHeader = [
    messages['label.id'], //hidden
    messages['label.id'], //ACROSS
    messages['label.name'], 
    <> 
      {/* Province */}
      <span className="display-full">{isExternal() ? messages['label.province'] : messages['label.district']}</span> 
      <span className="display-short" title={messages['label.province']}>
        {isExternal() ? messages['label.province.short'] : messages['label.district.short']}
      </span>
    </>, 
    <> 
    {/* Airport */}
    <span className="display-full">{messages['label.airport']}</span> 
    <span className="display-short" title={messages['label.office.airportCode']}>
      <i className="fa-solid fa-plane-up fa-lg"></i>
    </span>
  </>, 
  ]

  //Create list used by WET table
  const createOfficeList = (officeArray) => {
    let officeList = []

    //COLUMNS
    //* any changes to this you'll have to re-factor the 'data-column' to ensure it's filtering on the correct column
    officeArray.forEach((office) => { isExternal() ?
        //EXTERNAL columns
        officeList.push([
          office.number, //number used for the <Link dataId>
          office.across,
          <>
            {/* {office.name}  */}
            {addLineBreakAfterChar(office.name, '/')}
            {office.alias && <div className="geo-alias">{office.alias}</div>}
          </>,
          //Provinces
          <>
            <span className="display-full">{provinces.get(office.province)?.name}</span>
            <span className="display-short">
              <CustomTooltip placement="top" text={provinces.get(office.province)?.name}>
                {provinces.get(office.province)?.code}
              </CustomTooltip>
            </span>
          </>,
          //Airport
          office.airportCode ? addLineBreakAfterChar(office.airportCode, '/') : NON_PRINT_CHAR, // non-print char used by custom sort
          
        ])
        :
        //INTERNAL columns
        officeList.push([
          office.number, //number used for the <Link dataId>
          office.across,
          <>
            {office.name} 
            {/* FOR ADMINS - office.inactiveStatus presence means office is not active: -1 => before effective date, 1 => after expiry date. Prop not included if office is active */}
            {!!office.inactiveStatus && 
              <CustomTooltip placement="top" text={messages[office.inactiveStatus > 0 ? 'label.expired' : 'label.pending']}>
                <span className={`fa fa-exclamation-circle ${office.inactiveStatus > 0 ? 'expired-flag' : 'pending-flag'}`} />
                <span className="hide"> 
                  {messages['label.inactive']} {messages[office.inactiveStatus > 0 ? 'label.expired' : 'label.pending']} {/* Hidden text - for searching inactive, expired or pending offices */}
                </span>
              </CustomTooltip>
            }
            {!!office.internalOnly && 
              <CustomTooltip placement="top" text={messages['label.internalSite.text']}>
                <span className='fa fa-circle-minus internal-flag' />
                <span className="hide">
                  {messages['label.internalSite.simple']} {/* Hidden text - for searching inactive, expired or pending offices */}
                </span> 
              </CustomTooltip>
            }
            {office.alias && <div className="geo-alias">{office.alias}</div>}
          </>,
          districts.get(office.districtNumber)?.name,
          office.airportCode ?? NON_PRINT_CHAR, // non-print char used by custom sort
        ])
    });
    setOfficeList(officeList)
  }

  //Initial load code
  useEffect(() => {
    //TODO: still necessary? not sure if this is the best way to do it since this prevents spinner showing up in case page dynamically reloads
    // if(!domId("searchOffices").classList.contains('wb-tables-inited')) {
    //   setShowSpinner(true);
    // }

    createOfficeList(Array.from(offices.values()));

    const table = window.$('#searchOffices');

    //Wait for table to initialize
    table.on('init.dt', function () {
      const dataTable = table.DataTable();
      //Hide columns and set a default sort
      dataTable.column(0).visible(false); //ID column for external
      dataTable.order([2, 'asc']).draw();
      
      //Custom sort for airport column
      showBlanksLast('searchOffices', AIRPORT_COLUMN)

      //Search bar placeholder
      const searchOfficesContainer = document.querySelector('#searchOffices_filter');
      const officeSearchBar = searchOfficesContainer?.querySelector('input[type="search"]');
      if(officeSearchBar) {
        officeSearchBar.placeholder = messages[`label.search.placeholder.${isExternal() ? 'external' : 'internal'}`]; //put placeholder text (we don't control it, DataTables does)
      }

      setShowSpinner(false);
      domId('find-office')?.scrollIntoView({ behavior: 'smooth'}) //scroll page for optimal viewing
    });
  }, []);

  return (
    <div className="panel-body">
      <Spinner className="spinner" show={showSpinner}/>
      <h3>{isExternal() ? <FormattedMessage id="label.search.title.external" /> : <FormattedMessage id="label.search.title.internal" />}</h3>
      {
        <WETTable
          headers={customHeader}
          rows={officeList}
          addHref={{ 
            useHref: true, 
            clickFunction: updateActiveOffice,
            colForLink: 2, 
            colForData: 0,
          }}
          routeName="Office"
          tableId="searchOffices"
          rowId={0} //column to use for row's ID which we use during filtering
        ></WETTable>
      }
    </div>
  );
};

export default Search;
