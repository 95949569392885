/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useContext, useState, useRef, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { FormattedMessage } from "react-intl";
import AppContext from "../../components/AppContext-shared";
import { isInternal, sortArrayAlphabetically } from "../../utils/miscUtils-shared";

import Services from "../Services/index-shared";
import Search from "../Search/index-shared";
import Region from "../Region/index-shared";
import OfficeDialog from "../Office/officeDialog-shared";

import "./home-shared.css";

// TODO: look into WET style guide for design about moving elements (e.g. reflowing contents around grids) and use that instead of custom CSS
/* TODO: internal won't get updated service updates unless you hard reload the home page (since services are only initial load)   */
const Home = ({ navigation }) => {
  let currentOffice;
  let currentOfficeAirport;

  const Tabs = {
    INFO: "info",
    OFFICE: "office",
    SERVICES: "services",
    REGION: "region",
  };

  const { regions, messages, offices, activeOffice, user, showErrorMessage } = useContext(AppContext);

  const [activeTab, setActiveTab] = useState(Tabs.INFO);
  const [initializedTabs, setInitializedTabs] = useState([]); //keep track of which tabs have been initialized (don't load until visited, but then keep it once loaded)
  const [activeRegion, setActiveRegion] = useState(); //passed to the Region scene for initial filtering: region or service
  const [serviceData, setServiceData] = useState(); //pass service filter data from service tab to region when service link is clicked
  const [regionRefreshKey, setRegionRefreshKey] = useState(0); //force refresh region (for service tab links to region tab)

  //Remember initialized tabs
  useEffect(() => {
    if (!initializedTabs.includes(activeTab)) {
      setInitializedTabs([...initializedTabs, activeTab]);
    }
    // domId('wb-cont').scrollIntoView({ behavior: 'smooth'})
  }, [activeTab, initializedTabs]);

  //Function executed by service tab to switch to region tab with the selected filter applied
  const serviceTabLinkClicked = (serviceNumber) => {
    setActiveRegion(null);
    setServiceData(serviceNumber);
    setRegionRefreshKey((prevKey) => prevKey + 1);
    setActiveTab(Tabs.REGION);
  };

  //Convenience method for displaying active office header information below
  if (activeOffice) {
    currentOffice = offices.get(+activeOffice);
    currentOfficeAirport = {
      show:
        currentOffice?.airportCode ||
        currentOffice?.airportOfEntry?.internationalAirport === true,
      label:
        messages[
        `label.office.${currentOffice?.airportOfEntry?.internationalAirport === true
          ? "internationalAirport"
          : "airportCode"
        }`
        ],
      code: currentOffice?.airportCode ?? ''
    };
  }

  return (
    <main role="main" property="mainContentOfPage">
      {showErrorMessage &&
        <section className="alert alert-danger">
          {messages['http.500.content']}
        </section>
      }
      <h2 id="find-office">{messages["label.home.browseDirectory"]}</h2>

      {/* TABS */}
      <section className="tab-button-holder">

        <div id="tab-set">

          {/* Home */}
          <button id="info-tab" className={activeTab === Tabs.INFO ? "active-tab-button" : ""}
            onClick={() => { setActiveTab(Tabs.INFO); }} aria-label="Home"
          >
            <i className="fa-solid fa-house fa-lg"></i>
          </button>

          {(+offices?.size === 0 || +offices?.length === 0) ?
            // Tabs - loading
            <>
              <button id={Tabs.REGION} disabled>
                {!showErrorMessage && <i className="fa-solid fa-spinner tab-spinner fa-spin fa-xl"></i>}
                {isInternal() ? messages["label.region"] : messages["label.province"]}
              </button>
              <button id={Tabs.SERVICES} disabled>
                {!showErrorMessage && <i className="fa-solid fa-spinner tab-spinner fa-spin fa-xl"></i>}
                {messages["label.services"]}
              </button>
              <button id={Tabs.OFFICE} disabled>
                {!showErrorMessage && <i className="fa-solid fa-spinner tab-spinner fa-spin fa-xl"></i>}
                {messages['label.office']}
              </button>
            </>
            :
            // Tabs - loaded
            <>
              {/* Region */}
              <button id={Tabs.REGION} className={activeTab === Tabs.REGION ? "active-tab-button" : ""}
                onClick={() => { setActiveTab(Tabs.REGION); }}
              >
                <i className="fa-solid fa-map-location-dot fa-xl browse-icon"></i>
                {isInternal() ? messages["label.region"] : messages["label.province"]}
              </button>
              {/* Services */}
              <button id={Tabs.SERVICES} className={activeTab === Tabs.SERVICES ? "active-tab-button" : ""}
                onClick={() => { setActiveTab(Tabs.SERVICES); }}
              >
                <i className="fa-solid fa-handshake fa-xl browse-icon"></i>
                {messages["label.services"]}
              </button>
              {/* Office */}
              <button id={Tabs.OFFICE} className={activeTab === Tabs.OFFICE ? "active-tab-button" : ""}
                onClick={() => { setActiveTab(Tabs.OFFICE); }}
              >
                <i className="fa-solid fa-building fa-xl browse-icon"></i>
                {messages['label.office']}
              </button>
            </>
          }
        </div>
      </section>

      {/* BODY */}
      <section id="tab-body">
        {/* HOME / INFO TAB */}
        {/* TODO: put into its own component */}
        {activeTab == Tabs.INFO && (
          <section id="tab-info">
            <div className="welcome">
              <h3>
                {messages['home.welcomeUser']}
                {user?.isEditor && messages['label.admin']}
              </h3>
            </div>
            <p id="intro">
              <FormattedMessage id="home.paragraph2" />
            </p>
            <div id="expanded-info">
              <p> <FormattedMessage id="home.paragraph1" /> </p>
              <p> <FormattedMessage id="home.paragraph3" /> </p>
              <p>
                <FormattedMessage id="home.paragraph4" values={{ a: (chunks) => (<a href={messages["home.paragraph4.url"]}>{chunks}</a>), }} />
              </p>
              <p> <FormattedMessage id="home.paragraph5" /> </p>
            </div>
          </section>
        )}

        {/* REGION TAB */}

        {/* Internal - Region select tabs */}
        {activeTab === Tabs.REGION && isInternal() && (
          <div id="region-container" className="region-select btn-group btn-group-justified" >
            {sortArrayAlphabetically(Array.from(regions), 'name')
              .map(([key, loc]) => {
                return (
                  <a id={loc.number} role="button" key={loc.number}
                    className={`region-button btn btn-default ${loc.number == activeRegion ? "active-region" : ""}`}
                    onClick={() => {
                      setServiceData(null);
                      setActiveRegion(activeRegion === loc.number ? null : loc.number); //if user clicks the active region, unselect it (i.e. no region filter)
                    }}
                  >
                    {loc.name}
                  </a>
                );
              })}
          </div>
        )}

        {/* Region */}
        {initializedTabs.includes(Tabs.REGION) && (
          <div className={`tab-body ${activeTab === Tabs.REGION ? "active-body" : ""}`} >
            <Region
              key={activeRegion + regionRefreshKey}
              region={activeRegion}
              initialService={serviceData}
              navigation={navigation}
            />
          </div>
        )}

        {/* SERVICES TAB */}
        {initializedTabs.includes(Tabs.SERVICES) &&
          <div className={`tab-body ${activeTab === Tabs.SERVICES ? "active-body" : ""}`} >
            <Services serviceTabLinkClicked={serviceTabLinkClicked} />
          </div>
        }

        {/* OFFICE NAME TAB */}
        {initializedTabs.includes(Tabs.OFFICE) &&
          <div className={`tab-body ${activeTab === Tabs.OFFICE ? "active-body" : ""}`} >
            <Search />
          </div>
        }
      </section>

      <OfficeDialog currentOffice={currentOffice} currentOfficeAirport={currentOfficeAirport} />

    </main>
  );
};

export default Home;
